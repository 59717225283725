import styled from "styled-components";
import React, {Fragment, type SyntheticEvent, useCallback, useState} from "react";
import {ButtonPrimaryAlt} from "components/Buttons";
import {useDispatch, useSelector} from "react-redux";
import {getIsTermsNextSeason} from "modules/selectors";
import {hideNextSeasonModal, userAcceptNextSeasonTerms, userAcceptTerms, userInfoUpdate} from "modules/actions";
import {Checkbox} from "pages/Account";
import {Label} from "components/Form";
import {ExternalLink} from "components/Common";
import logo from "assets/img/logo-dark-fantasy.svg";
import {useLocation} from "react-router-dom";
import {GAME_SEASON} from "modules";

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  left: 0;
  top: 0;
`;

export const Modal = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  overflow: auto;
  max-width: 600px;
  min-height: 200px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  z-index: 101;
`;

const Button = styled(ButtonPrimaryAlt)`
  width: 100%;
  max-width: 200px;
`;

export const ModalInner = styled.div`
  position: relative;
  padding: 25px;
`;

export const ModalContent = styled.div`
  color: #343232;
  text-align: center;
`;

export const ModalTitle = styled.h4`
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 13px;
  color: #1e1450;
`;

export const ModalText = styled.p`
  color: #1e1450;
  font-size: 16px;
  line-height: 24px;
`;

export const ModalLogo = styled.img`
  width: 100%;
  height: auto;
  max-height: 110px;
  margin-bottom: 15px;
  max-width: 250px;
`;

const StyledLabel = styled(Label)`
  margin-left: 15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  width: 100%;
`;

const RowCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const ButtonsBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 50px;

	button,
	a {
		max-width: 260px;

		@media (max-width: 440px) {
			width: 80%;
			margin: 5px 0;
		}
	}

	@media (max-width: 440px) {
		flex-direction: column;
		margin-top: 20px;
	}
`;

export const TermsNextSeasonModal: React.FC = () => {
	const dispatch = useDispatch();
    const location = useLocation();
    const isVisible = useSelector(getIsTermsNextSeason);
    const [isChecked, setChecked] = useState(false);
    const [isOptInChecked, setIsOptInChecked] = useState(false);

    // Do not show terms on help page to be able to read terms&condition from terms modal link
    const isHelpSection = location.pathname.includes("/help") || location.pathname.includes("prizes");

    const onApplyTerms = useCallback(() => {
        if (!isChecked) return;
        dispatch(userAcceptTerms({terms: true, season: (GAME_SEASON+1).toString()}));
        dispatch(userInfoUpdate({opt_in: isOptInChecked}));
		dispatch(userAcceptNextSeasonTerms());
		dispatch(hideNextSeasonModal());
    }, [dispatch, isChecked, isOptInChecked]);

    const handleCheckbox = useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            setChecked(event.currentTarget.checked);
        },
        [setChecked]
    );

    const handleOptInCheckbox = useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            setIsOptInChecked(event.currentTarget.checked);
        },
        [setIsOptInChecked]
    );

	const handleHideSeason = useCallback(() => {
			dispatch(hideNextSeasonModal());
			sessionStorage.setItem("nextSeasonModalShowed", "true");
		},
		[dispatch]
	);

	if (!isVisible || isHelpSection) {
        return null;
    }

    return (
        <Fragment>
            <Overlay/>
            <Modal>
                <ModalInner>
                    <ModalContent>
                        <ModalLogo src={logo} alt="" role="banner"/>
                        <ModalTitle>Fantasy DP World Tour {GAME_SEASON+1}</ModalTitle>
                        <ModalText>
                            <span>
								You can now register for the {GAME_SEASON+1} season of Fantasy DP World Tour!
								To to play the {GAME_SEASON+1} season you must accept the Terms and Conditions.
								If you chose not to accept the {GAME_SEASON+1} T&Cs now you can do this later.
							</span>
                        </ModalText>
                        <RowCheckbox>
                            <Checkbox
                                name="opt_in"
                                id="opt_in"
                                checked={isOptInChecked}
                                onClick={handleOptInCheckbox}
                            />
                            <StyledLabel htmlFor="opt_in">
								I would like to receive email updates about Fantasy DP World Tour
                            </StyledLabel>
                        </RowCheckbox>
                        <RowCheckbox>
                            <Checkbox
                                name="terms"
                                id="apply-terms"
                                checked={isChecked}
                                onClick={handleCheckbox}
                                required={true}
                            />
                            <StyledLabel htmlFor="apply-terms">
                                I confirm that I am over 18 years old and I agree to the{" "}
                                <ExternalLink href="https://fantasy.dpworldtour.com/help/terms">
                                    Terms and Conditions
                                </ExternalLink>
                            </StyledLabel>
                        </RowCheckbox>
						<ButtonsBox>
							<Button disabled={!isChecked} onClick={onApplyTerms}>
								Submit
							</Button>
							<Button onClick={handleHideSeason}>
								I’ll do this later
							</Button>
						</ButtonsBox>
                    </ModalContent>
                </ModalInner>
            </Modal>
        </Fragment>
    );
};
