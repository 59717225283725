import {Api, ApiError} from "modules/utils";
import {call, put, select} from "typed-redux-saga";
import {
	autoFillTeam,
	autoFillTeamFailed,
	autoFillTeamSuccess,
	requestMyTeam,
	requestMyTeamFailed,
	requestMyTeamSuccess,
	requestRivalTeam,
	requestRivalTeamFailed,
	requestRivalTeamSuccess,
	saveTeam,
	saveTeamFailed,
	saveTeamSuccess,
} from "modules/actions/team";
import {showGlobalError, showNextSeasonModal} from "modules/actions";
import {getRollbackSeasonOrUndefined, getUser, getViewedTeam} from "modules/selectors";
import {identity, isEmpty, pickBy, first, get} from "lodash";

const trackSaveTeam = () =>
	window.dataLayer?.push({
		event: "save_team",
	});

export const showMyTeamSaga = function* ({payload: eventID}: ReturnType<typeof requestMyTeam>) {
	try {
		const season = yield* select(getRollbackSeasonOrUndefined);
		const response = yield* call(Api.FantasyTeam.showMy, eventID, season);
		yield* put(
			requestMyTeamSuccess({
				eventID,
				team: response.success.team,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(requestMyTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const showRivalTeamSaga = function* ({payload}: ReturnType<typeof requestRivalTeam>) {
	try {
		const season = yield* select(getRollbackSeasonOrUndefined);
		const response = yield* call(Api.FantasyTeam.showRivalTeam, payload, season);
		yield* put(
			requestRivalTeamSuccess({
				eventID: payload.eventID,
				team: response.success.team,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(requestRivalTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const saveTeamSaga = function* ({payload: eventID}: ReturnType<typeof saveTeam>) {
	try {
		const team = yield* select(getViewedTeam);
		const user = yield* select(getUser);
		const lineup = team?.lineup;

		const response = yield* call(
			Api.FantasyTeam.save,
			pickBy(
				{
					name: team?.name,
					lineup: isEmpty(lineup) ? undefined : lineup,
					captain: team?.captain,
				},
				identity
			)
		);

		const payload = {
			eventID,
			team: response.success.team,
		};

		yield* put(requestMyTeamSuccess(payload));
		yield* put(saveTeamSuccess(payload));

		trackSaveTeam();

		if(!user?.isNextSeasonTermsModalViewed) {
			yield* put(showNextSeasonModal());
		}
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(saveTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const saveTeamFromLocalStorageSaga = function* ({payload: eventID}: ReturnType<typeof saveTeam>) {
	try {
		const team = JSON.parse(sessionStorage.getItem("team") || "{}");
		const user = yield* select(getUser);
		const teamName = sessionStorage.getItem("teamName");
		const lineup = team?.lineup;

		const response = yield* call(
			Api.FantasyTeam.save,
			pickBy(
				{
					name: teamName,
					lineup: isEmpty(lineup) ? undefined : lineup,
					captain: get(team, "captain",  first(lineup)),
				},
				identity
			)
		);

		const payload = {
			eventID,
			team: response.success.team,
		};

		sessionStorage.removeItem("team");
		sessionStorage.removeItem("teamName");

		yield* put(requestMyTeamSuccess(payload));
		yield* put(saveTeamSuccess(payload));

		trackSaveTeam();

		if(!user?.isNextSeasonTermsModalViewed) {
			yield* put(showNextSeasonModal());
		}
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(saveTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const autofillTeamSaga = function* ({payload: eventID}: ReturnType<typeof autoFillTeam>) {
	try {
		const team = yield* select(getViewedTeam);
		const user = yield* select(getUser);
		const lineup = team?.lineup;

		const response = yield* call(
			Api.FantasyTeam.autofill,
			pickBy(
				{
					lineup: isEmpty(lineup) ? undefined : lineup,
					captain: team?.captain,
				},
				identity
			)
		);

		const payload = {
			eventID,
			team: response.success.team,
		};

		console.log("autoFillTeamSuccess")

		yield* put(requestMyTeamSuccess(payload));
		yield* put(autoFillTeamSuccess(payload));

		if(!user?.isNextSeasonTermsModalViewed) {
			yield* put(showNextSeasonModal());
		}
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(autoFillTeamFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const clearWithdrawnSaga = function* () {
	try {
		yield* call(Api.FantasyTeam.clear_withdrawn);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};
