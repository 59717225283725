import {IApiResponse, JSONError} from "modules/types";

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	// public static USER_EXPIRED_TOKEN = 510;
	public static USER_NOT_REGISTERED = 409;
	public code?: number;

	constructor(m: string, code = 0) {
		super(m);
		this.name = "API Error";
		this.message = m;
		this.code = code;
	}

	public static HAS_ERROR({errors}: IApiResponse) {
		return Boolean(Array.isArray(errors) && errors.length);
	}

	public static HAS_JSON_ERROR<T>(json: T | JSONError) {
		return (json as JSONError)?.code === 0;
	}

	/**
	 * Should be called after each request when we expect some errors or validations
	 */
	public static CHECK(response: IApiResponse) {
		if (ApiError.HAS_ERROR(response)) {
			const error = response.errors[0];
			throw new ApiError(error.message, error.code);
		}
	}

	public static CHECK_JSON<T>(json: T | JSONError, message: string) {
		if (ApiError.HAS_JSON_ERROR(json)) {
			throw new ApiError(message, 403);
		}
	}
}

export default ApiError;
