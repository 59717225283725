import {SagaIterator} from "redux-saga";
import {all, call, takeLatest} from "redux-saga/effects";
import {
	autoFillTeam,
	clearWithdrawn,
	createLeague, deleteRollbackLeagueRequest,
	fetchFaq,
	fetchGameRules,
	fetchGlobalSwingsRankings,
	fetchLeagueMonthlyRankings,
	fetchLeagueRankings,
	fetchLeagueUsersList,
	fetchMoreGlobalSwingsRankings,
	fetchMoreLeagueMonthlyRankings,
	fetchMoreLeagueRankings,
	fetchMoreLeagueUsersList,
	fetchMoreRolexRankings,
	fetchMoreTopTenRankings,
	fetchPrivacy,
	fetchPrizes, fetchRankingsMonthsRequest,
	fetchRolexRankings,
	fetchSchedule,
	fetchSwingsJsonRequest,
	fetchTerms,
	fetchTopTenRankings,
	globalError,
	inviteToLeague,
	joinToLeague,
	leaveLeague,
	loadMoreLeaguesForJoin,
	postContact,
	removeUserFromLeague,
	requestCountriesJSON,
	requestCurrentUser,
	requestEventsJSON,
	requestMyTeam,
	requestPlayersJSON,
	requestPlayerStatsJSON,
	requestResetPassword,
	requestRivalTeam,
	requestSeasonStatsJSON,
	resetPassword, rollbackAllLeaguesRequest, rollbackLeagueRequest,
	saveTeam, saveTeamFromLocalStorage,
	searchLeaguesForJoin,
	showLeague,
	showLeaguesForJoin,
	showMyLeagues,
	subscribeToLiveScores,
	updateLeague, userAcceptNextSeasonTerms,
	userAcceptTerms,
	userInfoUpdate,
	userLogin,
	userLogout,
	userRegister,
	userUpdate,
} from "modules/actions";
import {errorsManagerSaga} from "modules/sagas/errors";
import {
	AcceptNextSeasonTermsSaga,
	acceptTermsSaga,
	getCurrentUserSaga,
	loginSaga,
	logoutSaga,
	registerSaga,
	requestResetPasswordSaga,
	resetPasswordSaga,
	updateUserDetailsSaga,
	updateUserSaga,
} from "modules/sagas/user";
import {
	fetchSwingsSaga,
	pollingMessageJSONSaga,
	requestCountriesJSONSaga,
	requestEventsJSONSaga,
	requestFaqJSONSaga,
	requestGameRulesJSONSaga,
	requestPlayersJSONSaga,
	requestPlayerStatsJSONSaga,
	requestPrivacyJSONSaga,
	requestPrizesJSONSaga,
	requestScheduleJSONSaga,
	requestSeasonStatsJSONSaga,
	requestTermsJSONSaga,
} from "modules/sagas/json";
import {postContactSaga} from "modules/sagas/contact";
import {
	autofillTeamSaga,
	clearWithdrawnSaga, saveTeamFromLocalStorageSaga,
	saveTeamSaga,
	showMyTeamSaga,
	showRivalTeamSaga,
} from "modules/sagas/team";
import {fetchLiveScoresSaga} from "modules/sagas/liveScoring";
import {
	createLeagueSaga, deleteRollbackLeagueSaga, fetchRankingsMonthsSaga,
	globalSwingsRankingsSaga,
	joinToLeagueSaga,
	leagueMonthlyRankingsSaga,
	leagueRankingsSaga,
	leagueUsersListSaga,
	leaveLeagueSaga,
	loadMoreGlobalSwingsRankingsSaga,
	loadMoreLeagueMonthlyRankingsSaga,
	loadMoreLeagueRankingsSaga,
	loadMoreLeaguesForJoinSaga,
	loadMoreLeagueUsersListSaga,
	loadMoreRolexRankingsSaga,
	loadMoreTopTenRankingsSaga,
    rollbackAllLeaguesSaga,
    rollbackLeagueSaga,
	removeUserFromLeagueSaga,
	rolexRankingsSaga,
	searchLeaguesForJoinSaga,
	sendLeagueInvitesSaga,
	showLeagueSaga,
	showLeaguesForJoinSaga,
	showMyLeaguesSaga,
	topTenRankingsSaga,
	updateLeagueSaga,
} from "modules/sagas/leagues";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all([
		call(pollingMessageJSONSaga),
		/**
		 * JSON
		 */
		takeLatest(requestPlayersJSON, requestPlayersJSONSaga),
		takeLatest(requestPlayerStatsJSON, requestPlayerStatsJSONSaga),
		takeLatest(requestEventsJSON, requestEventsJSONSaga),
		takeLatest(requestCountriesJSON, requestCountriesJSONSaga),
		takeLatest(requestSeasonStatsJSON, requestSeasonStatsJSONSaga),

		takeLatest(fetchFaq, requestFaqJSONSaga),
		takeLatest(fetchGameRules, requestGameRulesJSONSaga),
		takeLatest(fetchPrizes, requestPrizesJSONSaga),
		takeLatest(fetchTerms, requestTermsJSONSaga),
		takeLatest(fetchPrivacy, requestPrivacyJSONSaga),
		takeLatest(fetchSchedule, requestScheduleJSONSaga),
		takeLatest(fetchSwingsJsonRequest, fetchSwingsSaga),

		/**
		 * Common
		 */
		takeLatest(globalError, errorsManagerSaga),
		takeLatest(postContact, postContactSaga),
		takeLatest(subscribeToLiveScores, fetchLiveScoresSaga),

		/**
		 * Auth
		 */
		takeLatest(userLogin, loginSaga),
		takeLatest(userRegister, registerSaga),
		takeLatest(userLogout, logoutSaga),
		takeLatest(requestResetPassword, requestResetPasswordSaga),
		takeLatest(resetPassword, resetPasswordSaga),

		/**
		 * User
		 */
		takeLatest(requestCurrentUser, getCurrentUserSaga),
		takeLatest(userUpdate, updateUserSaga),
		takeLatest(userInfoUpdate, updateUserDetailsSaga),
		takeLatest(userAcceptTerms, acceptTermsSaga),
		takeLatest(userAcceptNextSeasonTerms, AcceptNextSeasonTermsSaga),

		/**
		 * Team
		 */
		takeLatest(requestMyTeam, showMyTeamSaga),
		takeLatest(requestRivalTeam, showRivalTeamSaga),
		takeLatest(saveTeam, saveTeamSaga),
		takeLatest(saveTeamFromLocalStorage, saveTeamFromLocalStorageSaga),
		takeLatest(autoFillTeam, autofillTeamSaga),
		takeLatest(clearWithdrawn, clearWithdrawnSaga),

		/**
		 * Leagues
		 */
		takeLatest(createLeague, createLeagueSaga),
		takeLatest(inviteToLeague, sendLeagueInvitesSaga),
		takeLatest(showMyLeagues, showMyLeaguesSaga),
		takeLatest(showLeaguesForJoin, showLeaguesForJoinSaga),
		takeLatest(searchLeaguesForJoin, searchLeaguesForJoinSaga),
		takeLatest(loadMoreLeaguesForJoin, loadMoreLeaguesForJoinSaga),
		takeLatest(joinToLeague, joinToLeagueSaga),
		takeLatest(showLeague, showLeagueSaga),
		takeLatest(leaveLeague, leaveLeagueSaga),
		takeLatest(updateLeague, updateLeagueSaga),

		/**
		 * Rankings
		 */
		takeLatest(fetchLeagueRankings, leagueRankingsSaga),
		takeLatest(fetchMoreLeagueRankings, loadMoreLeagueRankingsSaga),
		takeLatest(fetchLeagueMonthlyRankings, leagueMonthlyRankingsSaga),
		takeLatest(fetchMoreLeagueMonthlyRankings, loadMoreLeagueMonthlyRankingsSaga),
		takeLatest(fetchRolexRankings, rolexRankingsSaga),
		takeLatest(fetchMoreRolexRankings, loadMoreRolexRankingsSaga),
		takeLatest(fetchTopTenRankings, topTenRankingsSaga),
		takeLatest(fetchMoreTopTenRankings, loadMoreTopTenRankingsSaga),
		takeLatest(fetchGlobalSwingsRankings, globalSwingsRankingsSaga),
		takeLatest(fetchMoreGlobalSwingsRankings, loadMoreGlobalSwingsRankingsSaga),
		takeLatest(rollbackAllLeaguesRequest, rollbackAllLeaguesSaga),
		takeLatest(rollbackLeagueRequest, rollbackLeagueSaga),
		takeLatest(deleteRollbackLeagueRequest, deleteRollbackLeagueSaga),
		takeLatest(fetchRankingsMonthsRequest, fetchRankingsMonthsSaga),

		takeLatest(fetchLeagueUsersList, leagueUsersListSaga),
		takeLatest(fetchMoreLeagueUsersList, loadMoreLeagueUsersListSaga),
		takeLatest(removeUserFromLeague, removeUserFromLeagueSaga),
	]);
};

export * from "./errors";
