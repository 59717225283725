import React, {Fragment, PropsWithChildren, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IUserLoginPayload, userLogin, userLogout, requestCurrentUser} from "modules/actions";
import {PagePreloader} from "components/Preloader";
import {getUser, isLoggedIn, isSessionChecked} from "modules/selectors";
import {IS_SECRET_PASSED} from "modules";
import {useLocation} from "react-router-dom";
import { trackUser } from "modules/utils";

export const GigyaGate: React.FC<PropsWithChildren<unknown>> = ({children}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const sessionChecked = useSelector(isSessionChecked);
	const user = useSelector(getUser);
	const isAuthenticated = useSelector(isLoggedIn);
	const [isChildrenVisible, setShowChildren] = useState(sessionChecked);
	const [userLoginPayload, setUserLoginPayload] = useState<IUserLoginPayload>();
	const isHelpSection = location.pathname.includes("/help") || location.pathname.includes("prizes");

	const onLogin = useCallback(() => {
		if (!IS_SECRET_PASSED) return;

		window.gigya?.accounts.getAccountInfo({
			include: "id_token,profile,data",
			callback: (resp) => {
				if (resp.errorCode) {
					return;
				}

				const {
					UID: gigya_id,
					UIDSignature: uid_signature,
					signatureTimestamp: signature_timestamp,
					data,
					profile
				} = resp;

				void trackUser({
					event: "userDetail",
					user_details: {
						user_id: gigya_id,
						user_emid: profile.email,
					}
				});

				setUserLoginPayload({
					uid: gigya_id,
					uidSignature: uid_signature,
					signatureTimestamp: signature_timestamp,
					country: data.address?.country,
				});
			},
		});
	}, []);

	const onLogout = useCallback(() => {
		setUserLoginPayload(undefined);

		if (isAuthenticated) {
			dispatch(userLogout());
		}
	}, [dispatch, isAuthenticated]);

	useEffect(() => {
		dispatch(requestCurrentUser());
	}, [dispatch]);

	useEffect(() => {
		setShowChildren(sessionChecked);
	}, [sessionChecked]);

	useEffect(() => {
		if (userLoginPayload && sessionChecked && !user) {
			dispatch(userLogin(userLoginPayload));
		}
	}, [dispatch, userLoginPayload, sessionChecked, user]);

	useEffect(() => {
		onLogin();

		window.gigya?.accounts.addEventHandlers({
			onLogin,
			onLogout,
		});
	}, [onLogin, onLogout]);

	if (!isChildrenVisible && !isHelpSection) {
		return <PagePreloader />;
	}

	return <Fragment>{children}</Fragment>;
};
