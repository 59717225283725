import {createReducer} from "redux-act";
import {setSeason} from "modules/actions/season";
import {GAME_SEASON_STRING} from "modules/constants";

interface IReducer {
	selectedSeason: string;
}

const defaultState: IReducer = {
	selectedSeason: GAME_SEASON_STRING
};

const onSetSeason = (state: IReducer, payload: string): IReducer => ({
	...state,
	selectedSeason: payload
})

export const season = createReducer<typeof defaultState>({}, defaultState)
	.on(setSeason, onSetSeason)
