import {createAction} from "redux-act";

export interface IInvitesModal {
	success: boolean;
	error?: string;
}
export const showInvitesModal = createAction<IInvitesModal>();
export const hideInvitesModal = createAction();

export const showLeagueRolloverModal = createAction();
export const hideLeagueRolloverModal = createAction();

export const hideWelcomeModal = createAction();

export const showAuthenticationModal = createAction();
export const hideAuthenticationModal = createAction();

export const showNextSeasonModal = createAction();
export const hideNextSeasonModal = createAction();
