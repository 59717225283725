import styled from "styled-components";

export const BaseButton = styled.button`
	border: 0;
	background: transparent;
	appearance: none;
	outline: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	font-size: 18px;
`;

export const ButtonPrimary = styled(BaseButton)`
	--btnTextColor: #fff;
	--btnHoverTextColor: var(--btnTextColor);
	--btnDisabledTextColor: var(--btnTextColor);

	--btnBackground: #ff2214;
	--btnHoverBackground: #ff5b4f;
	--btnDisabledBackground: #ffcdc3;

	--btnBorderColor: var(--btnBackground);
	--btnHoverBorderColor: var(--btnHoverBackground);
	--btnDisabledBorderColor: var(--btnDisabledBackground);

	--btnDisableOpacity: 0.5;

	font-weight: 300;
	font-size: 16px;
	line-height: 18px;
	background: var(--btnBackground);
	border: 1px solid var(--btnBorderColor);
	color: var(--btnTextColor);
	padding: 0 15px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 44px;
	border-radius: 22px;
	box-sizing: border-box;
	transition: all ease 0.4s;

	&:hover:not(:disabled) {
		background: var(--btnHoverBackground);
		border-color: var(--btnHoverBorderColor);
		color: var(--btnHoverTextColor);
	}

	&:disabled {
		background: var(--btnDisabledBackground);
		border-color: var(--btnDisabledBorderColor);
		color: var(--btnDisabledTextColor);
		cursor: default;
		opacity: var(--btnDisableOpacity);
	}
`;

export const ButtonPrimaryAlt = styled(ButtonPrimary)`
	--btnBackground: #1E1450;
	--btnHoverBackground: #4745c0;
	--btnDisabledBackground: #7371c4;
`;

export const ButtonPrimaryAltDark = styled(ButtonPrimary)`
	--btnBackground: #1E1450;
	--btnHoverBackground:#574F7C;
	--btnDisabledBackground: #8F89A7;
`;

export const ButtonSecondary = styled(ButtonPrimary)`
	--btnTextColor: #ff2214;
	--btnHoverTextColor: #fff;
	--btnDisabledTextColor: var(--btnHoverTextColor);
	--btnBackground: transparent;
	--btnHoverBackground: #ff2214;
	--btnDisabledBackground: #ffcdc3;
	--btnBorderColor: #ff2214;
	--btnDisableOpacity: 0.25;
`;

export const ButtonSecondaryAlt = styled(ButtonSecondary)`
	--btnTextColor: #3230be;
	--btnHoverTextColor: #fff;
	--btnHoverBackground: #3230be;
	--btnDisabledBackground: transparent;
	--btnBorderColor: #3230be;
	--btnDisableOpacity: 0.4;
	--btnDisabledBorderColor: #3230be;
	--btnDisabledTextColor: #3230be;
`;

export const ButtonSecondaryAltDark = styled(ButtonSecondary)`
	--btnTextColor: #1E1450;
	--btnHoverTextColor: #fff;
	--btnHoverBackground: #1E1450;
	--btnDisabledBackground: transparent;
	--btnBorderColor: #1E1450;
	--btnDisableOpacity: 0.4;
	--btnDisabledBorderColor: #1E1450;
	--btnDisabledTextColor: #1E1450;
`;

export const ButtonApproval = styled(ButtonPrimary)`
	--btnBackground: #00e68c;
	--btnHoverBackground: #41eba8;
	--btnDisabledBackground: var(--btnHoverBackground);
	--btnDisableOpacity: 0.4;
`;

export const ButtonWarning = styled(ButtonPrimary)`
	--btnBackground: #ff2214;
	--btnHoverBackground: #ff5b4f;
	--btnDisabledBackground: var(--btnHoverBackground);
	--btnDisableOpacity: 0.4;
`;

export const ButtonWarningAlt = styled(ButtonPrimary)`
	--btnTextColor: #ff2261;
	--btnHoverTextColor: #fff;
	--btnDisabledTextColor: var(--btnHoverTextColor);
	--btnBackground: transparent;
	--btnHoverBackground: #ff5a88;
	--btnDisabledBackground: var(--btnHoverBackground);
	--btnBorderColor: #ff2261;
	--btnDisableOpacity: 0.25;
`;
