import {
	hideInvitesModal,
	hideLeagueRolloverModal,
	hideWelcomeModal,
	showInvitesModal,
	showLeagueRolloverModal,
	showAuthenticationModal,
	hideAuthenticationModal, showNextSeasonModal, hideNextSeasonModal,
} from "modules/actions/modals";
import {createReducer} from "redux-act";
import {Storage} from "modules/utils";
import {SHOW_INITIAL_MODAL} from "modules/constants";
import {showAcceptTermsModal, userAcceptTermsFailed, userAcceptTermsSuccess} from "modules/actions";

const defaultState = {
    inviteSuccessModal: {
        isShow: false,
        error: "",
        success: false,
    },
    genericModal: {
        isShow: false,
    },
    welcomeModal: {
        isShow: JSON.parse(Storage.GET(SHOW_INITIAL_MODAL) || "true") as boolean,
    },
    termsModal: {
        isShow: false,
    },
	termsNextSeason: {
		isShow: false,
	},
    leagueRolloverModal: {
        isShow: false
    },
	authenticationModal: {
		isShow: false
	}
};

export interface IModalsReducer {
    inviteSuccessModal: {
        isShow: boolean;
        success: boolean;
        error?: string;
    };
    genericModal: {
        isShow: boolean;
    };
    welcomeModal: {
        isShow: boolean;
    };
    termsModal: {
        isShow: boolean;
    };
	termsNextSeason: {
		isShow: boolean;
	};
    leagueRolloverModal: {
        isShow: boolean;
    }
	authenticationModal: {
		isShow: boolean;
	}
}

const onHideWelcomeModal = (state: IModalsReducer) => ({
    ...state,
    welcomeModal: {
        isShow: false,
    },
});

const onHideTermsModal = (state: IModalsReducer) => ({
    ...state,
    termsModal: {
        isShow: false,
    },
});

const onShowLeagueRolloverModal = (state: IModalsReducer) => ({
    ...state,
    leagueRolloverModal: {
        isShow: true
    }
})
const onHideLeagueRolloverModal = (state: IModalsReducer) => ({
    ...state,
    leagueRolloverModal: {
        isShow: false
    }
})
const onShowAuthenticationModal = (state: IModalsReducer) => ({
	...state,
	authenticationModal: {
		isShow: true
	}
});
const onHideAuthenticationModal = (state: IModalsReducer) => ({
	...state,
	authenticationModal: {
		isShow: false
	}
});

export const modals = createReducer<IModalsReducer>({}, defaultState)
    .on(showInvitesModal, (state, payload) => ({
        ...state,
        ...payload,
        inviteSuccessModal: {
            ...state.inviteSuccessModal,
            isShow: true,
            success: payload.success,
        },
    }))
    .on(hideInvitesModal, (state) => ({
        ...state,
        inviteSuccessModal: {
            ...state.inviteSuccessModal,
            isShow: false,
            success: false,
            error: "",
        },
    }))
    .on(hideWelcomeModal, onHideWelcomeModal)
    .on(showAcceptTermsModal, (state) => ({
        ...state,
        termsModal: {isShow: true},
    }))
	.on(showNextSeasonModal, (state) => ({
		...state,
		termsNextSeason: {isShow: true},
	}))
	.on(hideNextSeasonModal, (state) => ({
		...state,
		termsNextSeason: {isShow: false},
	}))
    .on(userAcceptTermsSuccess, onHideTermsModal)
    .on(userAcceptTermsFailed, onHideTermsModal)
    .on(showLeagueRolloverModal, onShowLeagueRolloverModal)
    .on(hideLeagueRolloverModal, onHideLeagueRolloverModal)
	.on(showAuthenticationModal, onShowAuthenticationModal)
	.on(hideAuthenticationModal, onHideAuthenticationModal)
