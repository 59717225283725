import React, {Fragment, SyntheticEvent, useCallback} from "react";
import {
	CloseButton,
	Modal as ModalBase,
	ModalContent,
	ModalInner,
	Overlay,
	ModalTitle,
	ModalText,
} from "components/Modals/common";
import styled from "styled-components";
import {Checkbox as BaseCheckbox, ErrorText, Input, Label} from "components/Form";
import {ExternalLink} from "components/Common";
import {eq} from "lodash";
import {ButtonPrimaryAlt} from "components/Buttons";
import {
	clearUserRegistrationData,
	userClearError,
	userRegister,
	userRegisterFailed,
} from "modules/actions";
import {ApiError} from "modules/utils";
import {useDispatch, useSelector} from "react-redux";
import {getUserState} from "modules/selectors";
import {Exist} from "components/Exist";
import {GAME_SEASON_STRING, RequestState} from "modules";
import logoDark from "assets/img/fantlogo-dark.svg";

const Checkbox = styled(BaseCheckbox)``;

const ErrorMessage = styled(ErrorText)`
	margin-bottom: 20px;
`;

const Grid = styled.div`
	display: flex;
	flex-flow: column;
	gap: 20px;
	max-width: 400px;
	margin: 0 auto 40px;
`;

const RowCheckbox = styled.div`
	display: flex;
	align-items: center;
`;

const StyledLabel = styled(Label)`
	margin-left: 15px;
	font-size: 14px;
	line-height: 18px;
	font-weight: normal;
`;

const Modal = styled(ModalBase)`
	max-width: 560px;
	width: 100%;
	min-width: 320px;
`;

const Title = styled(ModalTitle)`
	font-size: 26px;
	line-height: 31px;
	margin-bottom: 5px;

	@media (max-width: 768px) {
		font-size: 20px;
	}
`;

const Logo = styled.img`
	width: 190px;
	height: auto;
	margin: 0 auto 30px;
`;

const ModalTextStyled = styled(ModalText)`
	font-size: 20px;
	line-height: 24px;
	max-width: 410px;
	margin: 0 auto 15px;
`;

const Button = styled(ButtonPrimaryAlt)`
	width: 100%;
	max-width: 380px;
`;

interface IRegistrationFormElement extends HTMLFormElement {
	username: HTMLInputElement;
	opt_in: HTMLInputElement;
	terms: HTMLInputElement;
}

export const RegistrationModal: React.FC = () => {
	const dispatch = useDispatch();
	const {error, requestState} = useSelector(getUserState);
	const isRegistrationDisabled = eq(requestState, RequestState.Requested);
	const hasError = Boolean(error);

	const onClose = useCallback(() => {
		dispatch(clearUserRegistrationData());
	}, [dispatch]);

	const reportError = useCallback(
		(error: string) => {
			dispatch(userRegisterFailed(new ApiError(error)));
			return true;
		},
		[dispatch]
	);

	const handleOnChange = useCallback(() => {
		dispatch(userClearError());
	}, [dispatch]);

	const handleFormSubmit = useCallback(
		(event: SyntheticEvent<IRegistrationFormElement>) => {
			event.preventDefault();

			const {username, opt_in, terms} = event.currentTarget;

			const validateList = [
				{field: username, error: "Please provide your team name"},
				{field: terms, error: "Please accept Terms & Conditions"},
			];

			const hasError = validateList.find(({field, error}) =>
				field.checkValidity() ? false : reportError(error)
			);

			if (hasError) {
				return;
			}

			return dispatch(
				userRegister({
					username: username.value,
					opt_in: opt_in.checked,
				})
			);
		},
		[reportError, dispatch]
	);

	return (
		<Fragment>
			<Overlay onClick={onClose} />
			<Modal>
				<ModalInner>
					<CloseButton onClick={onClose} />
					<ModalContent>
						<form
							onSubmit={handleFormSubmit}
							onChange={handleOnChange}
							noValidate={true}>
							<Logo src={logoDark} alt="DP World Tour Fantasy" />
							<Title>Fantasy DP World Tour {GAME_SEASON_STRING}</Title>
							<ModalTextStyled>
								Before you pick your players, please create a Team Name to appear on
								leaderboards.
							</ModalTextStyled>
							<Grid>
								<Input
									label="Team Name"
									name="username"
									type="text"
									placeholder="Team Name"
									inputProps={{maxLength: 40}}
									required={true}
									disabled={isRegistrationDisabled}
									defaultValue={sessionStorage.getItem("teamName") || ""}
								/>
								<RowCheckbox>
									<Checkbox
										name="opt_in"
										id="opt_in"
										disabled={isRegistrationDisabled}
									/>
									<StyledLabel htmlFor="opt_in">
										I would like to receive updates about Fantasy DP World Tour
									</StyledLabel>
								</RowCheckbox>
								<RowCheckbox>
									<Checkbox
										name="terms"
										id="terms"
										disabled={isRegistrationDisabled}
										required={true}
									/>
									<StyledLabel htmlFor="terms">
										I confirm that I am over 18 years old and I agree to the{" "}
										<ExternalLink href="https://fantasy.dpworldtour.com/help/terms">
											Terms and Conditions*
										</ExternalLink>
									</StyledLabel>
								</RowCheckbox>
							</Grid>
							<Exist when={hasError}>
								<ErrorMessage>{error}</ErrorMessage>
							</Exist>
							<Button type="submit" disabled={isRegistrationDisabled}>
								Submit
							</Button>
						</form>
					</ModalContent>
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
